import { Feature, MiniAction } from "./feature-typing";
import { doPostRequest } from "./request";
import {
  GateDataContainer,
  MergePayload,
  MiniActionPayload,
  OnLateMergeStrategy,
} from "./request-typings";
import { serializedObjectSize } from "./util";
import { eventQBus } from "@otto-ec/global-resources/event-q-bus";

/*                                                */
export const TOPIC_REQUESTS_MERGES = "tracking.bct.requests-merges";
export const TOPIC_REQUESTS_MINI_ACTIONS = "tracking.bct.requests-mini-actions";
/*                                                     */
export const TOPIC_BATCH_ONCE_NOTIFICATIONS = "tracking.bct.batch-once-notifications";
export const TOPIC_BATCH_OFTEN_NOTIFICATIONS = "tracking.bct.batch-often-notifications";

const isBeaconApiSupported =
  "navigator" in window && typeof window.navigator.sendBeacon === "function";

let onceBatchSent = false;

/**
 *
 *
 *
 */
export function initOnceBatchStrategy(notificationTopic: `${string}.${string}.${string}`) {
  eventQBus.on(notificationTopic, () => {
    onceBatchSent = true;
  });
}

/**
 *
 *
 */
export function shouldBatchOneTimeBatchRequests(): boolean {
  return canBatchRequests() && !onceBatchSent;
}

/**
 *
 *
 */
export function canBatchRequests(): boolean {
  return isBeaconApiSupported;
}

export interface SelfTrigger {
  enabled: boolean;
  delay: number;
  initialized: boolean;
}

export interface BatchMerge {
  mergeValues: GateDataContainer;
  onLate: OnLateMergeStrategy;
  features?: Feature[];
}

/**
 *
 *
 *
 *
 */
export class Batcher<T> {
  sendBatch: (url: string, batch: Batch<T>) => void;
  maxBatchBytes: number;
  batches: Map<string, Batch<T>>;
  notificationTopic: `${string}.${string}.${string}`;
  selfTrigger: SelfTrigger;
  /**
 *
 *
 *
 *
 *
 *
 *
 */
   
  constructor(
    entryTopic: `${string}.${string}.${string}`,
    notificationTopic: `${string}.${string}.${string}`,
    sendBatch: (url: string, message: Batch<T>) => void,
    maxBatchBytes: number = 55000,
    selfTriggerDelay: number = -1,
  ) {
    eventQBus.on(entryTopic, (message) => this.storeEntry(message));
    eventQBus.on(notificationTopic, () => this.sendBatches());

    this.sendBatch = sendBatch;
    this.maxBatchBytes = maxBatchBytes;
    this.batches = new Map();
    this.notificationTopic = notificationTopic;
    this.selfTrigger = {
      enabled: selfTriggerDelay > 0,
      delay: selfTriggerDelay,
      initialized: false,
    };
  }

  /**
 *
 *
 *
 */
  storeEntry(message: Message<T>) {
    const messageSize = serializedObjectSize(message);

    /*                                                                      */
    const batch = this.batches.get(message.key) || {
      browserId: message.browserId,
      url: message.url,
      clientMergeId: message.clientMergeId,
      entries: [],
      size: 0,
    };

    /*                                                 */
    const updatedBatch = {
      ...batch,
      entries: [...batch.entries, ...message.entries],
      size: batch.size + messageSize,
    };
    this.batches.set(message.key, updatedBatch);

    /*                                                                    */
    if (updatedBatch.size > this.maxBatchBytes) {
      this.sendBatch(message.key, updatedBatch);
      this.batches.delete(message.key);
    }

    /*                                                         */
    if (this.selfTrigger.enabled && !this.selfTrigger.initialized) {
      setTimeout(() => {
        eventQBus.emit(this.notificationTopic, "trigger-send-batches");
      }, this.selfTrigger.delay);
      this.selfTrigger.initialized = true;
    }
  }

  /**
 *
 *
 *
 */
  sendBatches() {
    this.batches.forEach((batch, url) => this.sendBatch(url, batch));
    this.batches = new Map();
    this.selfTrigger.initialized = false;
  }
}

/**
 *
 *
 *
 *
 *
 */
export function batchMergeRequest(gateUrl: string, browserId: string, payload: MergePayload): void {
  const entry: BatchMerge = {
    mergeValues: payload.mergeValues,
    onLate: payload.onLate,
    features: payload.features,
  };

  eventQBus.emit(TOPIC_REQUESTS_MERGES, {
    key: gateUrl,
    browserId,
    url: payload.url,
    clientMergeId: payload.clientMergeId,
    entries: [entry],
  });
}

/**
 *
 *
 *
 *
 *
 */
export function batchMiniActionRequest(
  gateUrl: string,
  browserId: string,
  payload: MiniActionPayload,
): void {
  eventQBus.emit(TOPIC_REQUESTS_MINI_ACTIONS, {
    key: gateUrl,
    browserId,
    url: payload.url,
    entries: payload.updates,
  });
}

/**
 *
 *
 *
 *
 */
export function sendMergeBatch(url: string, batch: Batch<BatchMerge>) {
  const payload = {
    browserId: batch.browserId,
    url: batch.url,
    clientMergeId: batch.clientMergeId,
    merges: batch.entries,
  };

  doPostRequest(url, payload);
}

/**
 *
 *
 *
 *
 */
export function sendMiniActionBatch(url: string, batch: Batch<MiniAction>) {
  const payload: MiniActionPayload = {
    browserId: batch.browserId,
    url: batch.url,
    updates: batch.entries,
  };

  doPostRequest(url, payload);
}

/**
 *
 *
 */
export function initBatching() {
  initOnceBatchStrategy(TOPIC_BATCH_ONCE_NOTIFICATIONS);

   
  new Batcher(TOPIC_REQUESTS_MERGES, TOPIC_BATCH_ONCE_NOTIFICATIONS, sendMergeBatch);
   
  new Batcher(
    TOPIC_REQUESTS_MINI_ACTIONS,
    TOPIC_BATCH_OFTEN_NOTIFICATIONS,
    sendMiniActionBatch,
    55000,
    1000,
  );

  const triggerBatchSend = () =>
    eventQBus.emit(TOPIC_BATCH_ONCE_NOTIFICATIONS, "trigger-send-batches");
  const triggerBatchOftenSend = () =>
    eventQBus.emit(TOPIC_BATCH_OFTEN_NOTIFICATIONS, "trigger-send-batches");

  /*                                               */
  setTimeout(() => {
    triggerBatchSend();
  }, 1500);

  /*                                   */
  window.addEventListener("beforeunload", () => {
    triggerBatchOftenSend();
    triggerBatchSend();
  });
}

interface Message<EntryType> {
  /**
 *
 */
  key: string;
  /**
 *
 */
  browserId: string;
  /**
 *
 */
  url: string;
  /**
 *
 */
  clientMergeId?: string;
  /**
 *
 */
  entries: Array<EntryType>;
}

interface Batch<EntryType> {
  /**
 *
 */
  browserId: string;
  /**
 *
 */
  url: string;
  /**
 *
 */
  entries: Array<EntryType>;
  /**
 *
 */
  clientMergeId?: string;
  /**
 *
 */
  size: number;
}

import { pop as popLinkData } from "./link-data";
import * as accessPath from "./accesspath";
import { getDeviceLabels } from "./browser-basics";
import { provideContext } from "./context-provider";
import { createRandomId } from "./compute-ids";
import { sendMergeToGate, sendEventToGate } from "./request";
import { submitStoredMove } from "./move";
import { extend } from "@otto-ec/global-resources/core";
import { DataContainer, parseDataContainer } from "./datacontainer";
import { TSID } from "./context";
import { EventMergeContext } from "./eventMergeContext";
import { internals } from "./util";

/**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
export function createEventMergeContext(): EventMergeContext {
  const id = createRandomId(10);
  return {
    sendEventToTrackingServer: (dataContainer: DataContainer) => sendEventToGate(dataContainer, id),
    submitEventMerge: async (dataContainer) => {
      await sendEventToGate(dataContainer, id);
    },
    eventMergeId: id,
  } as EventMergeContext;
}

export function forceInitialPageImpression(dataContainer: DataContainer) {
  const linkData = popLinkData();
  sendMergeToGate(extend(linkData, dataContainer), "default");
  submitStoredMove();
}

/**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
export function createContext(id: string, url: string, dataContainer?: DataContainer): Array<TSID> {
  const context = provideContext();
  const currentContextStack = context.create(id, url);

  forceInitialPageImpression(parseDataContainer(dataContainer || {}));

  return currentContextStack;
}

/**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
export function replaceContext(id: string, url: string, dataContainer: DataContainer): Array<TSID> {
  const context = provideContext();
  const currentContextStack = context.replace(id, url);

  forceInitialPageImpression(parseDataContainer(dataContainer));

  return currentContextStack;
}

/**
 *
 *
 */
export function closeContext(): void {
  const context = provideContext();

  if (context.stack.length > 1) {
    sendEventToGate({
      ts_LayerEvent: ["close"],
    });
  }

  context.close();
}

export function init() {
  if (internals.TRACKING_bctdatainitialized) {
    return;
  }
  internals.TRACKING_bctdatainitialized = true;

  accessPath.update(document.referrer);

  const params = {
    ...accessPath.getMergeParameters(),
    ...getDeviceLabels(),
  };
  sendMergeToGate(params);

  window.addEventListener("beforeunload", () => {
    accessPath.storeUnloadedLocationHref();
  });

  window.addEventListener("pagehide", () => {
    accessPath.storeUnloadedLocationHref();
  });
}

import initNitro from "./lib/nitro";
import initMove, { submitMove, submitMoveWithAction } from "./lib/move";
import { init as initHashTracking } from "./lib/hash-tracking";
import { init as initEchTracking } from "./lib/external-call-tracking";
import { init as initLinkData, trackOnNextPageImpression } from "./lib/link-data";
import {
  closeContext,
  createContext,
  replaceContext,
  createEventMergeContext,
  init as initBct,
} from "./lib/bct";
import {
  submitEvent,
  submitEventMerge,
  submitMerge,
  sendEventToGate,
  sendMergeToGate,
  sendMiniActionsToGate,
} from "./lib/request";
import { getMergeParameters, getPageId } from "./lib/url-helper";
import initAsync from "./lib/async";
import { initBatching } from "./lib/batching";
import { init as initViewtimeTracking } from "./lib/viewtime";

window.o_global.eventLoader.onReady(1000, initBatching);
window.o_global.eventLoader.onLoad(10000, initNitro);
window.o_global.eventLoader.onReady(1000, initMove);
window.o_global.eventLoader.onReady(1000, initLinkData);
window.o_global.eventLoader.onReady(1000, initBct);
window.o_global.eventLoader.onReady(1001, initAsync);
window.o_global.eventLoader.onReady(1001, initEchTracking);
window.o_global.eventLoader.onReady(1002, initHashTracking);
window.o_global.eventLoader.onReady(1002, initViewtimeTracking);

window.o_tracking = window.o_tracking || {};

window.o_tracking.bct = {
  closeContext,
  createContext,
  replaceContext,
  getMergeParameters,
  sendEventToTrackingServer: sendEventToGate,
  sendMergeToTrackingServer: sendMergeToGate,
  createEventMergeContext,
  sendEventMergeToTrackingServer: sendEventToGate,
  trackOnNextPageImpression,
  submitMove,
};

/*                   */
otto.tracking.assignNamespace({
  submitEvent,
  submitEventMerge,
  submitMerge,
  submitMove: (datacontainer, action) => {
    submitMoveWithAction(datacontainer, action);
  },
  submitMiniAction: async (updates) => {
    await sendMiniActionsToGate(updates);
  },
  createEventMergeContext,
  trackOnNextPageImpression: (datacontainer) => {
    trackOnNextPageImpression(datacontainer);
  },
  createContext: (id, url, datacontainer) => {
    createContext(id, url, datacontainer);
  },
  replaceContext: (id, url, datacontainer) => {
    replaceContext(id, url, datacontainer);
  },
  closeContext,
  getPageMergeId: getPageId,
});

/*                                                                                                                                  */
window.o_tracking.optOut = {
  disableNitro: () => {},
  enableNitro: () => {},
};

window.o_global.eventQBus.emitModuleLoaded("tracking.bct.bct");
